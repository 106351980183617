import type { INavLinkGroup } from "@m365-admin/nav";
import { IDashboardLink } from "components/InsightsComponents/types";
import { InsightsRoutes } from "utils/Links";

// A full list of support extentions can be found in the 'react-file-type-icons' here:
// https://github.com/microsoft/fluentui/blob/master/packages/react-file-type-icons/src/FileTypeIconMap.ts
const fileTypeExtensions = {
    excel: "xls",
    powerbi: "pbix",
    website: "website",
};

// See documentation here:
// https://admincontrolsdemoapps.blob.core.windows.net/demo-app/latest/DemoApp/index.html#/examples/nav
// Dashboards here are listed in the order in which they will appear in the side nav
// Links within each dashboard group will appear in the order in which they are listed on the Top Dashboards view
// But will be listed in alpha order on the side nav and in the specific dashboard view
export const insightsDashboards: IDashboardLink[] = [
    {
        name: "Acquisition Insights",
        key: "AcquisitionInsights",
        icon: "Table",
        links: [
            {
                name: "OOBE User Dashboard",
                key: "OOBE User Dashboard",
                href: "https://aka.ms/oobescoobeuserdashboardv2",
                screenshot: "img/dashboards/dashboard_oobe-user.png",
                extension: fileTypeExtensions.powerbi,
            },
            {
                name: "OOBE Device Dashboard",
                key: "OOBE Device Dashboard",
                href: "https://aka.ms/oobescoobedevicedashboardv2",
                screenshot: "img/dashboards/dashboard_oobe-device.png",
                extension: fileTypeExtensions.powerbi,
            },
            {
                name: "Historical Dime Dashboard",
                key: "Historical Dime Dashboard",
                href: "https://aka.ms/dimedashboardv2",
                screenshot: "img/dashboards/dashboard_historical-dime.png",
                extension: fileTypeExtensions.powerbi,
            },
            {
                name: "M365 OEM Redemption Dashboard",
                key: "M365 OEM Redemption Dashboard",
                href: "https://aka.ms/oemredemption",
                screenshot: "img/dashboards/dashboard_oem-redemption.png",
                extension: fileTypeExtensions.powerbi,
            },
        ],
    },
    {
        name: "ASHA",
        key: "ASHA_Home",
        icon: "ViewDashboard",
        links: [
            {
                name: "ASHA",
                key: "ASHA",
                href: "https://asha.microsoft.com/?M365App=Office",
                screenshot: "img/dashboards/dashboard_asha.png",
            },
            {
                name: "Copilot ASHA",
                key: "Copilot ASHA",
                href: "https://aka.ms/asha/copilot",
                screenshot: "img/dashboards/dashboard_asha-copilot.png",
            },
            {
                name: "Jadis ASHA",
                key: "Jadis ASHA",
                href: "https://asha.microsoft.com/?M365App=Jadis",
                screenshot: "img/dashboards/dashboard_asha-jadis.png",
            },
            {
                name: "NPS Causation",
                key: "NPS Causation",
                href: "https://asha.microsoft.com/nps?M365App=Office",
                screenshot: "img/dashboards/dashboard_asha-nps-causation.png",
            },
            {
                name: "Staging",
                key: "Staging",
                href: "https://asha.microsoft.com/staging?M365App=Office",
                screenshot: "img/dashboards/dashboard_asha-staging.png",
            },
            {
                name: "Comparison",
                key: "Comparison",
                href: "https://asha.microsoft.com/comparison?M365App=Office",
                screenshot: "img/dashboards/dashboard_asha-comparison.png",
            },
            {
                name: "Normalization",
                key: "Normalization",
                href: "https://asha.microsoft.com/normalization?M365App=Office",
                screenshot: "img/dashboards/dashboard_asha-normalization.png",
            },
            {
                name: "Trends",
                key: "Trends",
                href: "https://asha.microsoft.com/trends?M365App=Office",
                screenshot: "img/dashboards/dashboard_asha-trends.png",
            },
        ],
    },
    {
        name: "Consumer Insights",
        key: "ConsumerInsights",
        icon: "BIDashboard",
        links: [
            {
                name: "Consumer Growth Scorecard",
                key: "Consumer Growth Scorecard",
                href: InsightsRoutes.Consumer.path,
                screenshot: "img/dashboards/dashboard_consumer-growth-scorecard.png",
            },
            {
                name: "Harmony Web Funnel",
                key: "Harmony Web Funnel",
                href: InsightsRoutes.Consumer.path + "?section=Harmony",
                screenshot: "img/dashboards/dashboard_harmony.png",
            },
            {
                name: "Day 0 Session 0 Journey Funnel",
                key: "Consumer - Day 0 Session 0 Journey Funnel",
                href: InsightsRoutes.Day0Funnel.path,
                screenshot:
                    "img/dashboards/dashboard_consumer-day-0-session-0-journey-funnel.png",
            },
            {
                name: "eRFM",
                key: "eRFM",
                href: InsightsRoutes.ERFM.path,
                screenshot: "img/dashboards/dashboard_erfm.png",
            },
            {
                name: "eRFM Cannibalization Report",
                key: "eRFM Cannibalization Report",
                href: "https://aka.ms/eRFMCannibalizationReport",
                screenshot: "img/dashboards/dashboard_eRFM_Cannibalization.png",
                extension: fileTypeExtensions.powerbi,
            },
            {
                name: "CAMAU Overview",
                key: "CAMAU Overview",
                href: InsightsRoutes.ConsumerCAMAU.path,
                screenshot: "img/dashboards/dashboard_consumer-camau.png",
            },
            {
                name: "Growth Drilldown",
                key: "Growth Drilldown",
                href: InsightsRoutes.GrowthDrilldown.path,
                screenshot: "img/dashboards/dashboard_growth-drilldown.png",
            },
        ],
    },
    {
        name: "Copilot",
        key: "Copilot",
        icon: "Table",
        links: [
            {
                name: "Office Copilot Scorecard",
                key: "Office Copilot Scorecard",
                href: InsightsRoutes.OfficeCopilotScorecard.path,
                screenshot: "img/dashboards/dashboard_copilot-scorecard.png",
                enableReferralTelemetryViaUrl: true,
            },
            {
                name: "Copilot ASHA",
                key: "Copilot ASHA",
                href: "https://aka.ms/asha/copilot",
                screenshot: "img/dashboards/dashboard_asha-copilot.png",
            },
            {
                name: "Copilot Tenant Insights",
                key: "Copilot Tenant Insights",
                href: InsightsRoutes.Copilot.path,
                screenshot:
                    "img/dashboards/dashboard_copilot-tenantinsights-overview.png",
            },
        ],
    },
    {
        name: "Experimentation",
        key: "Experimentation",
        icon: "TestBeaker",
        links: [
            {
                name: "Time to Insight",
                key: "Time to Insight",
                href: "https://aka.ms/ogai/tti",
                screenshot: "img/dashboards/dashboard_experimentation_tti.png",
                extension: fileTypeExtensions.powerbi,
            },
            {
                name: "Experiment Radar",
                key: "Experiment Radar",
                href: "https://aka.ms/ExpRadar",
                screenshot:
                    "img/dashboards/dashboard_experimentation_releaseradar.png",
                extension: fileTypeExtensions.website,
            },
            {
                name: "Traffic Size Calculators",
                key: "Traffic Size Calculators",
                href: "https://aka.ms/trafficcalculators",
                screenshot:
                    "img/dashboards/dashboard_experimentation_powercalculator.png",
                extension: fileTypeExtensions.excel,
            },
            {
                name: "Control Tower",
                key: "Control Tower",
                href: "https://exp.microsoft.com",
                screenshot: "img/dashboards/dashboard_experimentation_exp.png",
                extension: fileTypeExtensions.website,
            },
        ],
    },
    {
        name: "Qualitative Insights",
        key: "Qualitative Insights",
        icon: "Feedback",
        links: [
            {
                name: "Admin Control Settings",
                key: "Admin Control Settings",
                href: "https://msit.powerbi.com/groups/fb8ecb96-7ff4-479d-a128-02fac598a7aa/reports/cb47c763-0fb3-4220-bbfe-dfc076e0f67e",
                screenshot: "img/dashboards/dashboard_admin-control-settings.png",
                extension: fileTypeExtensions.powerbi,
            },
            {
                name: "OCV",
                key: "OCV",
                href: "https://ocv.microsoft.com/#/discover/?searchtype=OcvSurveys&relDateType=all&offset=0&allAreas&spt=Filters",
                screenshot: "img/dashboards/dashboard_ocv.png",
                extension: fileTypeExtensions.website,
            },
            {
                name: "Survey Funnel",
                key: "Survey Funnel",
                href: "https://aka.ms/fgsurveyfunnel",
                screenshot: "img/dashboards/dashboard_survey-funnel.png",
                extension: fileTypeExtensions.powerbi,
            },
            {
                name: "FG2.0 Survey Funnel DrillDown",
                key: "FG2.0 Survey Funnel DrillDown",
                href: InsightsRoutes.QuipSurveyFunnelDrillDown.path,
                screenshot: "img/dashboards/dashboard_fg-survey-funnel.png",
            },
        ],
    },
    {
        name: "Self-Serve",
        key: "Self-Serve",
        icon: "AnalyticsReport",
        links: [
            {
                name: "Dora",
                key: "Dora",
                href: "https://aka.ms/dora",
                screenshot: "img/dashboards/dashboard_selfserve-landingpage.png",
                extension: fileTypeExtensions.website,
            },
        ],
    },
    {
        name: "Strategic Tenant Insights",
        key: "Strategic Tenant Insights",
        icon: "StackedLineChart",
        links: [
            {
                name: "Copilot Tenant Insights",
                key: "Copilot Tenant Insights",
                href: InsightsRoutes.Copilot.path,
                screenshot:
                    "img/dashboards/dashboard_copilot-tenantinsights-overview.png",
            },
            {
                name: "Win32 Tenant Insights",
                key: "Win32 Overview",
                href: InsightsRoutes.Win32.path,
                screenshot: "img/dashboards/dashboard_win32-overview.png",
            },
            {
                name: "Cross Platform Overview",
                key: "Cross Platform Overview",
                href: InsightsRoutes.CrossPlatformOverview.path,
                screenshot: "img/dashboards/dashboard_cross-platform-overview.png",
            },
            {
                name: "Web Tenant Insights",
                key: "Web Overview",
                href: InsightsRoutes.Web.path,
                screenshot: "img/dashboards/dashboard_web-overview.png",
            },
            {
                name: "M365 Tenant Insights",
                key: "M365 Tenant Insights",
                href: InsightsRoutes.M365TenantInsights.path,
                screenshot: "img/dashboards/dashboard_m365-tenant-insights.png",
            },
            {
                name: "Error Lookup",
                key: "Error Lookup",
                href: InsightsRoutes.ErrorLookup.path,
                screenshot: "img/dashboards/dashboard_error-lookup.png",
            },
            {
                name: "Exemption Portal",
                key: "Exemption Portal",
                href: InsightsRoutes.ExemptionPortal.path,
                screenshot: "img/dashboards/dashboard_exemption-portal.png",
            },
            {
                name: "Mac Tenant Insights",
                key: "Mac Overview",
                href: InsightsRoutes.Mac.path,
                screenshot: "img/dashboards/dashboard_mac-overview.png",
            },
            {
                name: "MTR",
                key: "MTR",
                href: InsightsRoutes.MTR.path,
                screenshot: "img/dashboards/dashboard_mtr.png",
            },
            {
                name: "Teams Tenant Insights",
                key: "Teams Overview",
                href: InsightsRoutes.Teams.path,
                screenshot: "img/dashboards/dashboard_teams-overview.png",
            },
        ],
    },
    {
        name: "Summarized Insights",
        key: "Summarized Insights",
        icon: "PowerPointDocument",
        links: [
            {
                name: "Summarized Insights",
                key: "Summarized Insights",
                href: InsightsRoutes.SummarizedInsightsRequest.path,
                screenshot: "img/dashboards/dashboard_summarized-insights.png",
            },
        ],
    },
];

export const HOME_NAV_KEY = "HomeLink";

export const navLinkGroups: INavLinkGroup[] = [
    {
        key: "HomeGroup",
        links: [
            {
                name: "Home",
                icon: "Home",
                key: HOME_NAV_KEY,
                href: InsightsRoutes.InsightsLanding.path,
            },
        ],
    },
    {
        key: "AllDashboardsGroup",
        links: insightsDashboards,
    },
    {
        key: "Access",
        links: [
            {
                name: "Access",
                key: "ConsumerInsightsAccess",
                icon: "Lock",
                href: "https://office.visualstudio.com/OC/_wiki/wikis/EH%20Fuel%20Insights/59350/Customer-Health-Insights?anchor=access-request-for-dashboard",
                target: "_blank",
            },
        ],
    },
];
