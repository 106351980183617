import { TeamsSubWorkload, Workload } from "config/PlatformConfig";
import { MetricsResponseTableType, MetricsResponseType } from "pages/commonTypes";
import {
    coreMetricsHealthColourIndex,
    coreMetricsHealthScoreIndex,
} from "utils/Constants";
import { formatNumberCompact } from "utils/Helpers";

export const formatCoreMetrics = (
    coreHealthJson: MetricsResponseType,
    avgCohortMetricsJson: MetricsResponseType,
    workload: string,
    subWorkload?: string,
    application?: string,
    date?: string,
) => {
    const coreHealth: MetricsResponseTableType = coreHealthJson.Tables[0];
    const avgCohortsCoreMetricsTable: MetricsResponseTableType =
        avgCohortMetricsJson.Tables[0];
    let coreHealthScore: number = 0;
    let coreHealthColor: string = "Gray";
    const tenantCoreHealthMetric: {} = getCoreHealthMetricTemplate(
        workload,
        subWorkload,
        date,
        application,
    );
    if (coreHealth.Rows.length > 0) {
        coreHealthScore =
            coreHealth.Rows[0][
                coreHealth.Columns.length - coreMetricsHealthScoreIndex
            ];
        coreHealthColor =
            coreHealth.Rows[0][
                coreHealth.Columns.length - coreMetricsHealthColourIndex
            ];

        coreHealth.Columns.forEach((metricColumn, idx) => {
            const metric: string = metricColumn.ColumnName;
            const cols = metric.split("_");
            const metricName = cols.length > 1 ? cols[1] : cols[0];
            // case when [Color|MoM|YoY|Goal]_<metricName>
            if (metricName in tenantCoreHealthMetric) {
                if (cols.length > 1) {
                    switch (cols[0]) {
                        case "Color":
                            tenantCoreHealthMetric[metricName].Status =
                                coreHealth.Rows[0][idx];
                            break;
                        case "MoM":
                            tenantCoreHealthMetric[metricName].MomDelta =
                                coreHealth.Rows[0][idx];
                            break;
                        case "YoY":
                            tenantCoreHealthMetric[metricName].YoyDelta =
                                coreHealth.Rows[0][idx];
                            break;
                        case "Goal":
                            tenantCoreHealthMetric[metricName].Goal =
                                coreHealth.Rows[0][idx];
                            break;
                    }
                } else {
                    let metricValueString = "NA";
                    const metricValue = coreHealth.Rows[0][idx];
                    if (metricValue != null) {
                        if (tenantCoreHealthMetric[metricName].NumberFormatter) {
                            metricValueString =
                                tenantCoreHealthMetric[metricName].NumberFormatter(
                                    metricValue,
                                );
                        } else {
                            metricValueString = `${metricValue}`;
                        }
                        metricValueString = `${metricValueString}${tenantCoreHealthMetric[metric].Unit}`;
                    }
                    tenantCoreHealthMetric[metric].MetricValue = metricValueString;
                }
            }
        });
        if (avgCohortsCoreMetricsTable.Rows.length > 0)
            for (let i = 0; i < avgCohortsCoreMetricsTable.Rows.length; i++) {
                const cohort: string = avgCohortsCoreMetricsTable.Rows[i][0];

                for (
                    let idx = 1;
                    idx < avgCohortsCoreMetricsTable.Columns.length;
                    idx++
                ) {
                    const metric: string =
                        avgCohortsCoreMetricsTable.Columns[idx].ColumnName;

                    if (metric in tenantCoreHealthMetric) {
                        tenantCoreHealthMetric[metric].AverageCohortValues[cohort] =
                            avgCohortsCoreMetricsTable.Rows[i][idx] === 0
                                ? "NA"
                                : `${avgCohortsCoreMetricsTable.Rows[i][idx]}${tenantCoreHealthMetric[metric].Unit}`;
                    }
                }
            }
    }
    return { tenantCoreHealthMetric, coreHealthScore, coreHealthColor };
};

export const formatAppMetrics = (appMetricsJson: MetricsResponseType) => {
    const appMetricsTable = appMetricsJson.Tables[0];
    const displayMaping = {};
    const groupMaping = {};
    const res2 = {};
    appMetricsTable.Rows.forEach((x) => {
        const metric = x[1];

        if (!(metric in res2)) {
            res2[metric] = {};
            displayMaping[metric] = x[2];
            groupMaping[metric] = x[3];
        }

        res2[metric][x[4]] = Number.isNaN(+x[5])
            ? x[5]
            : Number(parseFloat(x[5]).toFixed(2));
    });
    const flatten = [];
    Object.keys(res2).forEach((x) => {
        const tmp = res2[x];
        tmp["Metric"] = x;
        tmp["Display"] = displayMaping[x];
        tmp["Group"] = groupMaping[x];
        if (tmp["Metric"] === "WebMAU") {
            tmp["Outlook"] = "NA"; // Web MAU for outlook is not shown as Tenant Insights for web has not on-boarded outlook yet.
        }
        flatten.push(tmp);
    });

    return flatten;
};

export const formatScoreLines = (
    sparkLineScoresJson: MetricsResponseType,
    workload: string,
    subWorkload?: string,
    application?: string,
    date?: string,
) => {
    const sparkLineScoresTable: MetricsResponseTableType =
        sparkLineScoresJson.Tables[0];
    // To do - Add type for sparkLineScore
    const sparkLineScores = [];
    sparkLineScoresTable.Rows.forEach((element) => {
        sparkLineScores.push(
            getTenantMetricValueMapping(
                element,
                workload,
                subWorkload,
                date,
                application,
            ),
        );
    });
    return sparkLineScores;
};

export const getCoreHealthMetricTemplate = (
    platform,
    subWorkload = null,
    date?: string,
    application?: string,
): Object => {
    switch (platform) {
        case Workload.WIN32:
            return {
                Engagement: {
                    MetricName: "Engagement",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                PerfP95: {
                    MetricName: "Boot Performance (P95)",
                    Definition: "",
                    Unit: "s",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "P95",
                },
                BootThreshold: {
                    MetricName: "Boot Threshold",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Link: "BootThreshold",
                },
                Watson: {
                    MetricName: "Watson",
                    Definition: "",
                    Unit: "",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "Watson",
                },
                UAE: {
                    MetricName: "UAE",
                    Definition: "",
                    Unit: "",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "UAE",
                },
                OCV: {
                    MetricName: "Feedback",
                    Definition: "",
                    Unit: "",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Link: "OCV",
                },
                Currency: {
                    MetricName: "Currency",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Link: "%in-service",
                },
            };
        case Workload.WEB:
            let reliabilityMetrics = {};
            if (IsWebV2Model(date)) {
                reliabilityMetrics = {
                    ASHA: {
                        MetricName: "ASHA",
                        Definition: "",
                        Unit: "",
                        MetricValue: "",
                        MomDelta: 0,
                        Status: "Blue",
                        AverageCohortValues: {},
                        DeltaColor: false,
                        Link: "Open",
                    },
                };
            } else {
                reliabilityMetrics = {
                    ACEFreeUsersRate: {
                        MetricName: "ACE Free Users",
                        Definition: "",
                        Unit: "%",
                        MetricValue: "",
                        MomDelta: 0,
                        Status: "Blue",
                        AverageCohortValues: {},
                        DeltaColor: false,
                    },
                    ICEFreeUsersRate: {
                        MetricName: "ICE Free Users",
                        Definition: "",
                        Unit: "%",
                        MetricValue: "",
                        MomDelta: 0,
                        Status: "Blue",
                        AverageCohortValues: {},
                        DeltaColor: false,
                    },
                };
            }

            return {
                MauRate: {
                    MetricName: "MAU Rate",
                    Definition: "MAU/TenantTotalMau",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                MeuRate: {
                    MetricName: "MEU Rate",
                    Definition: "MEU/MAU",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                EngagedEditorsRate: {
                    MetricName: "EngagedEditorsRate",
                    Definition: "EngagedEditors/MEU",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                ReturningMauRate: {
                    MetricName: "Returning MAU",
                    Definition: "ReturningMau/MAU",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                EUPLP95: {
                    MetricName: "Performance (P95)",
                    Definition: "",
                    Unit: "s",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "EUPL P95",
                },
                EUPLP50: {
                    MetricName: "Performance (P50)",
                    Definition: "",
                    Unit: "s",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "EUPL P50",
                },
                ...reliabilityMetrics,
                Feedback: {
                    MetricName: "Feedback",
                    Definition: "NPS + SaS",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                OpenInClientRate: {
                    MetricName: "OpenInClient Rate",
                    Definition: "OpenInClient/MAU",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                },
            };
        case Workload.MAC:
            return {
                Engagement: {
                    MetricName: "Engagement",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                PerfP95: {
                    MetricName: "Boot Performance (P95)",
                    Definition: "",
                    Unit: "s",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "Perf_P95",
                },
                BootThreshold: {
                    MetricName: "Boot Threshold",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Link: "Mac_BootThreshold",
                },
                CH: {
                    MetricName: "Crash Hang",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "CH_Rate",
                },
                FQ: {
                    MetricName: "Force Quit",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "FQ_Rate",
                },
                NPS: {
                    MetricName: "Feedback",
                    Definition: "",
                    Unit: "",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Link: "NPS",
                },
                OSCurrency: {
                    MetricName: "OS Currency",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Link: "% OS Currency",
                },
                AppCurrency: {
                    MetricName: "Build Currency",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "NA",
                    MomDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Link: "% Build Currency",
                },
            };
        case Workload.TEAMS:
            switch (subWorkload) {
                case TeamsSubWorkload.MEETING:
                    return {
                        UsageRate: {
                            MetricName: "Attach Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        StreamEstablishedSuccessPercentage: {
                            MetricName: "Stream Established Success Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        DropRatePercentage: {
                            MetricName: "Drop Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: true,
                        },
                        AudioPSRPercentage: {
                            MetricName: "Network Poor Stream Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: true,
                        },
                        PoorCPURate: {
                            MetricName: "Poor CPU Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: true,
                        },
                        PoorMemoryRate: {
                            MetricName: "Poor Memory Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: true,
                        },
                        PoorFrequencyRate: {
                            MetricName: "Poor Frequency Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: true,
                        },
                        FeedbackPoorPercentage: {
                            MetricName: "Poor Call Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: true,
                        },
                        NPS: {
                            MetricName: "Teams NPS",
                            Definition: "",
                            Unit: "",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                    };
                case TeamsSubWorkload.CHAT:
                    return {
                        UsageRate: {
                            MetricName: "Attach Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        ChatSwitchV2: {
                            MetricName: "Chat Switch V2 Percentage",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        NewChat: {
                            MetricName: "New Chat Percentage",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        ChatSendMessage: {
                            MetricName: "Chat Send Message Percentage",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        P95ChatSwitchV2: {
                            MetricName: "P95 Chat Switch V2",
                            Definition: "",
                            Unit: "s",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: true,
                        },
                        P50ChatSwitchV2: {
                            MetricName: "P50 Chat Switch V2",
                            Definition: "",
                            Unit: "s",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: true,
                        },
                        NPS: {
                            MetricName: "Teams NPS",
                            Definition: "",
                            Unit: "",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                    };
                default:
                    return {};
            }
        case Workload.WEB_CONSUMER:
            return {
                MAURate: {
                    MetricName: "MAU Rate",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                CoreActionMAURate: {
                    MetricName: "CoreActionMAU Rate",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                RetentionRate: {
                    MetricName: "Retention",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                EUPLP95: {
                    MetricName: "Performance (P95)",
                    Definition: "",
                    Unit: "s",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "EUPL P95",
                },
                EUPLP50: {
                    MetricName: "Performance (P50)",
                    Definition: "",
                    Unit: "s",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: true,
                    Link: "EUPL P50",
                },
                ASHA: {
                    MetricName: "ASHA",
                    Definition: "Aggregate Session Happiness",
                    Unit: "",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
                Feedback: {
                    MetricName: "Feedback",
                    Definition: "NPS + SaS",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: 0,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                },
            };
        case Workload.WEB_CONSUMER_GROWTH:
            return {
                MAU: {
                    MetricName: "MAU",
                    Definition: "",
                    Unit: "",
                    MetricValue: "",
                    MomDelta: undefined,
                    YoyDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    NumberFormatter: formatNumberCompact,
                    Goal: undefined,
                },
                CAMAU: {
                    MetricName: "CAMAU",
                    Definition: "",
                    Unit: "",
                    MetricValue: "",
                    MomDelta: undefined,
                    YoyDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    NumberFormatter: formatNumberCompact,
                    Goal: undefined,
                },
                PercentCAMAU: {
                    MetricName: "CAMAU %",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: undefined,
                    YoyDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Goal: undefined,
                },
                CAMAUDay0Pct: {
                    MetricName: "CAMAU Day 0 %",
                    Definition: "",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: undefined,
                    YoyDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Goal: undefined,
                },
                M1R: {
                    MetricName: "M1R",
                    Definition: "Available 28 days after selected date",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: undefined,
                    YoyDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Goal: undefined,
                },
                M3R: {
                    MetricName: "M3R",
                    Definition: "Available 84 days after selected date",
                    Unit: "%",
                    MetricValue: "",
                    MomDelta: undefined,
                    YoyDelta: undefined,
                    Status: "Blue",
                    AverageCohortValues: {},
                    DeltaColor: false,
                    Goal: undefined,
                },
            };
        case Workload.COPILOT_COMMERCIAL:
            switch (subWorkload) {
                case "Health":
                    switch (application) {
                        case "M365 Chat":
                            return {
                                FCFRP75: {
                                    MetricName: "FCFR P75",
                                    Definition: "First Chunk of First Response P75",
                                    Unit: "s",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                LCFRP75: {
                                    MetricName: "LCFR P75",
                                    Definition: "First Chunk First Response P75",
                                    Unit: "s",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                M1Retention: {
                                    MetricName: "M1Retention",
                                    Definition: "1-Month Retention",
                                    Unit: "%",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                MAURate: {
                                    MetricName: "MAURate",
                                    Definition: "MAU Rate",
                                    Unit: "%",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                Availability: {
                                    MetricName: "Availability",
                                    Definition: "AvailabilityRate", //TODO: Add the metric definitions
                                    Unit: "%",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                QPUU: {
                                    MetricName: "QPUU",
                                    Definition: "Query Per Unique User",
                                    Unit: "",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                CitationRate: {
                                    MetricName: "CitationRate",
                                    Definition: "Citation Rate",
                                    Unit: "%",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                SATRate: {
                                    MetricName: "SATRate",
                                    Definition: "SATRate",
                                    Unit: "",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                            };
                        default:
                            return {
                                FCFRP50: {
                                    MetricName: "FCFR P50",
                                    Definition: "First Chunk of First Response P50",
                                    Unit: "s",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                FCFRP95: {
                                    MetricName: "FCFR P95",
                                    Definition: "First Chunk First Response P95",
                                    Unit: "s",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                Currency: {
                                    MetricName: "Currency",
                                    Definition:
                                        "CC+MEC channels: #Devices on latest 2 app version releases / Total Devices for Copilot sessions on Win32 devices",
                                    Unit: "%",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                SeenRate: {
                                    MetricName: "Seen Rate",
                                    Definition: "Seen / Enabled",
                                    Unit: "%",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                TriedRate: {
                                    MetricName: "Tried Rate",
                                    Definition: "Tried / Seen",
                                    Unit: "%",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                KeepRate: {
                                    MetricName: "Keep Rate",
                                    Definition: "Kept / Tried",
                                    Unit: "%",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                M1Retention: {
                                    MetricName: "M1 Retention",
                                    Definition: "1-Month Retention",
                                    Unit: "%",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                Intensity: {
                                    MetricName: "Intensity",
                                    Definition: "#Tried Counts / User",
                                    Unit: "",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                SATPctRate: {
                                    MetricName: "SAT",
                                    Definition: "Thumbs Down / 100K tries",
                                    Unit: "",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                MTE: {
                                    MetricName: "MTE",
                                    Definition:
                                        "Minimal Trustworthy Experience Survey",
                                    Unit: "",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                                NPS: {
                                    MetricName: "NPS",
                                    Definition: "Copilot Net Promoter Score",
                                    Unit: "",
                                    MetricValue: "NA",
                                    MomDelta: undefined,
                                    Status: "Blue",
                                    AverageCohortValues: {},
                                    DeltaColor: false,
                                },
                            };
                    }
                case "Readiness":
                    return {
                        OneDrivePctRate: {
                            MetricName: "% OneDrive Business Users",
                            Definition: "% OneDrive Business Users",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        MIPEnabledPctRate: {
                            MetricName: "% MIP Enabled Users",
                            Definition: "% MIP Enabled Users",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        CopilotEnabledPctRate: {
                            MetricName: "Copilot Enabled %",
                            Definition:
                                "(Enabled Copilot Licenses / Available Copilot Licenses)",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        SPOLabelling: {
                            MetricName: "SPO Enabled",
                            Definition: "If SPO Sensitivity Labelling is Enabled",
                            Unit: "",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Gray",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        CollabProtection: {
                            MetricName: "Collab Enabled",
                            Definition: "If SPO Collab Labelling is Enabled",
                            Unit: "",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Gray",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        FbkAndLogsONPctScore: {
                            MetricName: "In-Product Feedback + Logs",
                            Definition:
                                "% of copilot enabled users that have in-product feedback + logfiles enabled",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        FbkSurveyONPctScore: {
                            MetricName: "In-product Surveys",
                            Definition:
                                "% of copilot enabled users that have in-product surveys enabled",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        ODDRate: {
                            MetricName: "Office Data Diagnostic Rate",
                            Definition: "",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        ConnectedExperienceRate: {
                            MetricName: "Connected Experience",
                            Definition:
                                "% of copilot enabled users that have connected experiences policy enabled",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                        ChannelReadinessRate: {
                            MetricName: "Channel Readiness Rate",
                            Definition:
                                "Channel Readiness Rate (Copilot Assigned users)",
                            Unit: "%",
                            MetricValue: "NA",
                            MomDelta: undefined,
                            Status: "Blue",
                            AverageCohortValues: {},
                            DeltaColor: false,
                        },
                    };
                default:
                    return {};
            }

        default:
            return {};
    }
};

export const getTenantMetricValueMapping = (
    metricList,
    platform,
    subWorkload,
    date?: string,
    application?: string,
) => {
    switch (platform) {
        case Workload.WIN32:
            return {
                Date: metricList[0],
                Engagement: metricList[1],
                "Engagement Color": metricList[2],
                Currency: metricList[3],
                "Currency Color": metricList[4],
                Feedback: metricList[5],
                "Feedback Color": metricList[6],
                Watson: metricList[7],
                "Watson Color": metricList[8],
                UAE: metricList[9],
                "UAE Color": metricList[10],
                "Boot Performance (P95)": metricList[11],
                "Boot Performance (P95) Color": metricList[12],
                "Boot Threshold": metricList[13],
                "Boot Threshold Color": metricList[14],
            };
        case Workload.WEB:
            if (IsWebV2Model(date)) {
                return {
                    Date: metricList[0],
                    "MAU Rate": metricList[1],
                    "MAU Rate Color": metricList[2],
                    "MEU Rate": metricList[3],
                    "MEU Rate Color": metricList[4],
                    EngagedEditorsRate: metricList[5],
                    "EngagedEditorsRate Color": metricList[6],
                    "Returning MAU": metricList[7],
                    "Returning MAU Color": metricList[8],
                    "Performance (P95)": metricList[9],
                    "Performance (P95) Color": metricList[10],
                    "Performance (P50)": metricList[11],
                    "Performance (P50) Color": metricList[12],
                    ASHA: metricList[13],
                    "ASHA Color": metricList[14],
                    Feedback: metricList[15],
                    "Feedback Color": metricList[16],
                    "OpenInClient Rate": metricList[17],
                    "OpenInClient Rate Color": metricList[18],
                };
            }

            return {
                Date: metricList[0],
                "MAU Rate": metricList[1],
                "MAU Rate Color": metricList[2],
                "MEU Rate": metricList[3],
                "MEU Rate Color": metricList[4],
                EngagedEditorsRate: metricList[5],
                "EngagedEditorsRate Color": metricList[6],
                "Returning MAU": metricList[7],
                "Returning MAU Color": metricList[8],
                "Performance (P95)": metricList[9],
                "Performance (P95) Color": metricList[10],
                "Performance (P50)": metricList[11],
                "Performance (P50) Color": metricList[12],
                "ACE Free Users": metricList[13],
                "ACE Free Users Color": metricList[14],
                "ICE Free Users": metricList[15],
                "ICE Free Users Color": metricList[16],
                Feedback: metricList[17],
                "Feedback Color": metricList[18],
                "OpenInClient Rate": metricList[19],
                "OpenInClient Rate Color": metricList[20],
            };
        case Workload.MAC:
            return {
                Date: metricList[0],
                Engagement: metricList[1],
                "Engagement Color": metricList[2],
                "OS Currency": metricList[3],
                "OS Currency Color": metricList[4],
                Feedback: metricList[5],
                "Feedback Color": metricList[6],
                "Crash Hang": metricList[7],
                "Crash Hang Color": metricList[8],
                "Force Quit": metricList[9],
                "Force Quit Color": metricList[10],
                "Boot Performance (P95)": metricList[11],
                "Boot Performance (P95) Color": metricList[12],
                "Boot Threshold": metricList[13],
                "Boot Threshold Color": metricList[14],
                "Build Currency": metricList[15],
                "Build Currency Color": metricList[16],
            };
        case Workload.TEAMS:
            switch (subWorkload) {
                case TeamsSubWorkload.MEETING:
                    return {
                        Date: metricList[0],
                        "Attach Rate": metricList[1],
                        "Attach Rate Color": metricList[2],
                        "Stream Established Success Rate": metricList[3],
                        "Stream Established Success Rate Color": metricList[4],
                        "Drop Rate": metricList[5],
                        "Drop Rate Color": metricList[6],
                        "Network Poor Stream Rate": metricList[7],
                        "Network Poor Stream Rate Color": metricList[8],
                        "Poor CPU Rate": metricList[9],
                        "Poor CPU Rate Color": metricList[10],
                        "Poor Memory Rate": metricList[11],
                        "Poor Memory Rate Color": metricList[12],
                        "Poor Frequency Rate": metricList[13],
                        "Poor Frequency Rate Color": metricList[14],
                        "Poor Call Rate": metricList[15],
                        "Poor Call Rate Color": metricList[16],
                        "Teams NPS": metricList[17],
                        "Teams NPS Color": metricList[18],
                    };
                case TeamsSubWorkload.CHAT:
                    return {
                        Date: metricList[0],
                        "Attach Rate": metricList[1],
                        "Attach Rate Color": metricList[2],
                        "Chat Switch V2 Percentage": metricList[19],
                        "Chat Switch V2 Percentage Color": metricList[20],
                        "New Chat Percentage": metricList[25],
                        "New Chat Percentage Color": metricList[26],
                        "Chat Send Message Percentage": metricList[27],
                        "Chat Send Message Percentage Color": metricList[28],
                        "P95 Chat Switch V2": metricList[21],
                        "P95 Chat Switch V2 Color": metricList[22],
                        "P50 Chat Switch V2": metricList[23],
                        "P50 Chat Switch V2 Color": metricList[24],
                        "Teams NPS": metricList[17],
                        "Teams NPS Color": metricList[18],
                    };
                default:
                    return {};
            }
        case Workload.WEB_CONSUMER:
            return {
                Date: metricList[0],
                "MAU Rate": metricList[1],
                "MAU Rate Color": metricList[2],
                "CoreActionMAU Rate": metricList[3],
                "CoreActionMAU Rate Color": metricList[4],
                Retention: metricList[5],
                "Retention Color": metricList[6],
                "Performance (P95)": metricList[7],
                "Performance (P95) Color": metricList[8],
                "Performance (P50)": metricList[9],
                "Performance (P50) Color": metricList[10],
                ASHA: metricList[11],
                "ASHA Color": metricList[12],
                Feedback: metricList[13],
                "Feedback Color": metricList[14],
            };
        case Workload.WEB_CONSUMER_GROWTH:
            return {
                Date: metricList[0],
                MAU: metricList[1],
                "MAU Color": metricList[2],
                "CAMAU Day 0 %": metricList[3],
                "CAMAU Day 0 % Color": metricList[4],
                "CAMAU %": metricList[5],
                "CAMAU % Color": metricList[6],
                CAMAU: metricList[7],
                "CAMAU Color": metricList[8],
                M1R: metricList[9],
                "M1R Color": metricList[10],
                M3R: metricList[11],
                "M3R Color": metricList[12],
            };
        case Workload.COPILOT_COMMERCIAL:
            switch (subWorkload) {
                case "Readiness":
                    return {
                        Date: metricList[0],
                        "% OneDrive Business Users": metricList[1],
                        "% OneDrive Business Users Color": metricList[2],
                        "% MIP Enabled Users": metricList[3],
                        "% MIP Enabled Users Color": metricList[4],
                        "Copilot Enabled %": metricList[5],
                        "Copilot Enabled % Color": metricList[6],
                        "SPO Enabled": metricList[7],
                        "SPO Enabled Color": metricList[8],
                        "Collab Enabled": metricList[9],
                        "Collab Enabled Color": metricList[10],
                        "In-Product Feedback + Logs": metricList[11],
                        "In-Product Feedback + Logs Color": metricList[12],
                        "In-product Surveys": metricList[13],
                        "In-product Surveys Color": metricList[14],
                        "Office Data Diagnostic Rate": metricList[15],
                        "Office Data Diagnostic Rate Color": metricList[16],
                        "Connected Experience": metricList[17],
                        "Connected Experience Color": metricList[18],
                        "Channel Readiness Rate": metricList[19],
                        "Channel Readiness Rate Color": metricList[20],
                    };
                case "Health":
                    switch (application) {
                        case "M365 Chat":
                            return {
                                Date: metricList[0],
                                MAURate: metricList[1],
                                "MAURate Color": metricList[2],
                                Availability: metricList[3],
                                "Availability Color": metricList[4],
                                "FCFR P75": metricList[5],
                                "FCFR P75 Color": metricList[6],
                                "LCFR P75": metricList[7],
                                "LCFR P75 Color": metricList[8],
                                QPUU: metricList[9],
                                "QPUU Color": metricList[10],
                                M1Retention: metricList[11],
                                "M1Retention Color": metricList[12],
                                CitationRate: metricList[13],
                                "CitationRate Color": metricList[14],
                                SATRate: metricList[15],
                                "SATRate Color": metricList[16],
                            };
                        default:
                            return {
                                Date: metricList[0],
                                "FCFR P50": metricList[1],
                                "FCFR P50 Color": metricList[2],
                                "FCFR P95": metricList[3],
                                "FCFR P95 Color": metricList[4],
                                Currency: metricList[5],
                                "Currency Color": metricList[6],
                                SAT: metricList[7],
                                "SAT Color": metricList[8],
                                "M1 Retention": metricList[9],
                                "M1 Retention Color": metricList[10],
                                "Seen Rate": metricList[11],
                                "Seen Rate Color": metricList[12],
                                "Keep Rate": metricList[13],
                                "Keep Rate Color": metricList[14],
                                "Tried Rate": metricList[15],
                                "Tried Rate Color": metricList[16],
                                Intensity: metricList[17],
                                "Intensity Color": metricList[18],
                                MTE: metricList[19],
                                "MTE Color": metricList[20],
                                NPS: metricList[21],
                                "NPS Color": metricList[22],
                            };
                    }
                default:
                    return {};
            }
    }
};

export const IsWebV2Model = (date: string) => {
    if (typeof date !== "string")
        return new Error("Date should be in string format");

    return new Date(date) >= new Date("2023-07-01");
};
